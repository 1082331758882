@import "palette-streapp";
// style document containing all variables and mixins that form the bare minimum of the streapp theme
// All rules in this document are application independent

//  TABLE OF CONTENTS:
//
//  Sass Functions
//  Variables
//  Typography
//  Buttons
//  Breadcrumbs
//  Help panel
//  Tables
//  Progressbars
//  Forms
//  Navbar
//  Sidenav
//  DragList
//  Searchbar
//  Generic search
//  Footer
//  Cookies
//  Widget Cards
//  Layout
//  Feedback
//  Chips
//  Badges
//  CardGrid
//  Tabs
//  Dialogs
//  Widget Articles
//  Article list
//  Verified Icon
//  Widgets Setting Dialog
//  Checkboxes and Radio Buttons

// Typography

/// Map deep get
/// @author Hugo Giraudel
/// @access public
/// @param {Map} $map - Map
/// @param {Arglist} $keys - Key chain
/// @return {*} - Desired value
@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

//  Variables

// makes sure text is not selectable
%noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

//  Typography

ul,
ul li,
ul li ul,
ul li ul li {
  list-style: none;
}

%text-dark {
  color: $zwart-400 !important;
}

%text-light {
  color: $white !important;
}

@mixin page-title() {
  font-size: 46px !important;
  line-height: 60px !important;
  font-weight: 400 !important;
}

@mixin header-text() {
  font-size: 30px !important;
  line-height: 36px !important;
  font-weight: 400 !important;
}

@mixin sub-header-text() {
  font-size: 24px !important;
  line-height: 28px !important;
  font-weight: 400 !important;
}

@mixin leading-text() {
  font-size: 22px !important;
  line-height: 32px !important;
}

@mixin std-text() {
  font-size: 17px !important;
  line-height: 27px !important;
}

@mixin breadcrumb-text() {
  font-size: 14px !important;
  line-height: 27px !important;
}

@mixin header-website-title() {
  font-size: 20px !important;
  line-height: 22px !important;
  font-weight: 400 !important;
}

@mixin header-website-subtitle() {
  font-size: 17px !important;
  line-height: 27px !important;
}

@mixin menubar-item() {
  font-size: 14px !important;
  line-height: 27px !important;
}

@mixin button-text() {
  font-size: 17px !important;
  line-height: 19px !important;
}

.text-dark {
  @extend %text-dark;
}

.text-light {
  @extend %text-light;
}

.primary {
  color: map-get($mat-streappblauw, 500) !important;
}

.accent {
  color: map-get($mat-streappaccent, 500) !important;
}

.grayed-out {
  color: $zwart-100 !important;
}

.page-title {
  @include page-title();
}

.header-text {
  @include header-text();
}

.sub-header-text {
  @include sub-header-text();
}

.leading-text {
  @include leading-text();
}

.std-text {
  @include std-text();
}

.breadcrumb-text {
  @include breadcrumb-text();
}

.header-website-title {
  @include header-website-title();
}

.header-website-subtitle {
  @include header-website-subtitle();
}

.menubar-item {
  @include menubar-item();
}

.button-text {
  @include button-text();
}

.bg-primary {
  background-color: map-get($mat-streappblauw, 500) !important;
}

.bg-primary-50 {
  background-color: map-get($mat-streappblauw, 50) !important;
}

.bg-primary-100 {
  background-color: map-get($mat-streappblauw, 100) !important;
}

.bg-primary-200 {
  background-color: map-get($mat-streappblauw, 200) !important;
}

.bg-primary-300 {
  background-color: map-get($mat-streappblauw, 300) !important;
}

.bg-primary-400 {
  background-color: map-get($mat-streappblauw, 400) !important;
}

.bg-primary-600 {
  background-color: map-get($mat-streappblauw, 600) !important;
}

.bg-primary-700 {
  background-color: map-get($mat-streappblauw, 700) !important;
}

.bg-primary-800 {
  background-color: map-get($mat-streappblauw, 800) !important;
}

.bg-primary-900 {
  background-color: map-get($mat-streappblauw, 900) !important;
}

.bg-accent {
  background-color: map-get($mat-streappaccent, 500) !important;
}

.bg-accent-100 {
  background-color: map-get($mat-streappaccent, 100) !important;
}

.bg-accent-200 {
  background-color: map-get($mat-streappaccent, 200) !important;
}

.bg-accent-300 {
  background-color: map-get($mat-streappaccent, 300) !important;
}

.bg-accent-400 {
  background-color: map-get($mat-streappaccent, 400) !important;
}

.bg-accent-700 {
  background-color: map-get($mat-streappaccent, 700) !important;
}

.bg-accent-900 {
  background-color: map-get($mat-streappaccent, 900) !important;
}

.bg-secondary {
  background-color: $brand-secondary !important;
}

.bg-secondary-support {
  background-color: $secondary-support !important;
}

.bg-zwart {
  background-color: $zwart-500 !important;
}

.bg-zwart-10 {
  background-color: $zwart-10 !important;
}

.bg-zwart-50 {
  background-color: $zwart-50 !important;
}

.bg-zwart-100 {
  background-color: $zwart-100 !important;
}

.bg-zwart-200 {
  background-color: $zwart-200 !important;
}

.bg-zwart-300 {
  background-color: $zwart-300 !important;
}

.bg-zwart-400 {
  background-color: $zwart-400 !important;
}

.bg-zwart-600 {
  background-color: $zwart-600 !important;
}

.bg-zwart-700 {
  background-color: $zwart-700 !important;
}

.bg-zwart-800 {
  background-color: $zwart-800 !important;
}

.bg-zwart-900 {
  background-color: $zwart-900 !important;
}

.primary-text {
  color: map-get($mat-streappblauw, 1000) !important;
  font-size: 14px !important;
  font-weight: bold;
}

// Buttons
.button-trailing-icon {
  mat-icon {
    line-height: 20px;
  }
}

a {
  color: map-get($mat-streappblauw, 500);
}

// Navbar
.margin-top {
  padding-top: 20px;
}

.profile-image-nav {
  object-fit: cover;
}

navbar {
  display: block;
}

.navbar-height {
  width: 1px;
  height: 90px;
}

.navbar {
  position: fixed;
  z-index: 11;
  width: calc(100vw - 61px); //Take away the margins of the sidebar
  top: 0;
  left: 60px;

  .upper-nav {
    height: 90px;
    background-color: map-get($mat-streappblauw, 50);
    color: $white;
  }

  mat-toolbar {
    color: white;

    .menu-item {
      height: 48px;
      border-radius: 0;
      margin-left: 5px;
      margin-right: 5px;

      .mat-button-wrapper span {
        padding-left: 5px;
      }
    }

    .menu-item.active {
      border-bottom-color: map-get($mat-streappgoud, 1000);
    }

    .menu-option.active {
      border-bottom-color: map-get($mat-streappgoud, 1000);
    }

    .profileButton.active {
      border-bottom-color: map-get($mat-streappgoud, 1000);
    }
  }
}

@media only screen and (max-width: 767px) {
  .navbar {
    position: absolute;
    z-index: 10;
    width: 100%;
    top: 0;

    .upper-nav {
      height: 50px;
    }
  }
}

// Loading
.loading {
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;

  .loading-text {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    h1 {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 0;
    }
  }

  mat-progress-spinner {
    top: 0;
    left: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }
}

// Errortext
.errorText {
  display: flex;
  min-height: 300px;
  padding-top: 50px;
  width: 100%;
}

// Video items
.overview-item {
  padding: 0 5px !important;
  margin-top: 10px;

  .video-preview {
    img {
      height: 110px;
      width: 110px;
      object-fit: cover;
    }

    img.photo {
      min-height: 110px;
      min-width: 110px;
      max-height: 220px;
      max-width: 220px;
      width: auto;
      height: auto;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  .video-overlay {
    height: 35px;
    position: absolute;
    left: 0;
    top: 75px;
    width: 100%;
    display: flex;
    background-color: rgba(0, 0, 0, 0.5);
    color: $white;
    justify-content: space-between;
  }
}

// H1 styling
h1.pageTitle {
  color: map-get($mat-streappblauw, 1000);
  font-weight: bold;

  span {
    padding-left: 10px;
    padding-right: 10px;
    border-bottom: 3px solid map-get($mat-streappgoud, 1000);
  }
}

.page-padding {
  padding: 20px;
  box-sizing: border-box;
}

// Ellipses
.ellipsified {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.ellipsified-2 {
  -webkit-line-clamp: 2 !important;
}

.ellipsified-3 {
  -webkit-line-clamp: 3 !important;
}

.ellipsified-4 {
  -webkit-line-clamp: 4 !important;
}

// Mat-Card
.mat-card:hover {
  background-color: $zwart-5;
}

// Sidenav
.mat-drawer-container.mat-sidenav-container {
  background-color: map-get($mat-streappblauw, 50);
  color: $zwart-500;

  .sidenav-header {
    padding: 10px 0 10px 25px;
  }

  .mat-drawer {
    max-width: 500px;
    min-width: 200px;

    .mat-expansion-panel {
      margin: 0 5px;
      box-shadow: none;
      width: 201px;

      .mat-icon {
        color: $zwart-300;
      }

      &:first-of-type {
        margin-top: 5px;
      }
    }

    .mat-button-disabled {
      background: inherit;
    }
  }
}

// Widget Cards
.hide-card-padding {
  margin-right: -16px !important;
  margin-left: -16px !important;
}

.mat-card {
  color: $zwart-500;
  box-shadow: none !important;
  border: 1px solid $zwart-100 !important;
}

.widget {
  margin-bottom: 8px;

  &.mat-card {
    padding-top: 8px;
  }

  .widget-header {
    width: 100%;
    height: 40px;
    background-color: $transparent;
    color: map-get($mat-streappblauw, 500);
    padding: 0px;

    .widget-header-text {
      &.profile-header {
        padding-left: 0px;
      }
    }
  }

  .mat-card-content {
    margin-bottom: 48px;
  }

  .widget-content {
    .mat-list .mat-list-item .mat-list-item-content {
      color: map-get($mat-streappblauw, 500);
      padding: 0;
    }
  }
}

.tabbed-card {
  mat-tab-header {
    height: 50px;
    margin-bottom: 10px;

    .mat-tab-label {
      height: 50px;
      min-width: 100px;
    }

    .mat-tab-label-active {
      font-weight: bold;
      color: map-get($mat-streappblauw, 500);
      opacity: 1;
    }
  }

  .mat-tab-header-pagination-controls-enabled {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    margin-left: 0px;
    margin-right: 0px;
  }

  .mat-list .mat-list-item .mat-list-item-content {
    padding-left: 0px;
    padding-right: 0px;
  }
}

// Layout
.desktop-width-limiter {
  height: 100%;
  color: inherit;
  background-color: inherit;
}

.title-spacer {
  flex: 1 1 auto;
}

.float-right {
  float: right;
}

.scroll-button {
  position: fixed;
  bottom: 4%;
  right: 5%;
  z-index: 9;
}

@keyframes pulse {
  0% {
    opacity: 0.4;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.4;
  }
}

.mat-drawer-container {
  min-height: 100%;

  &.mat-drawer-opened {
    max-height: 100%;
  }

  .mat-drawer-content {
    position: static;
    overflow-y: hidden;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .mat-drawer-inner-container {
    overflow: hidden; // Hide scrollbar on main sidenav
  }
}

.helpBox {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  display: flex;
  justify-content: flex-end;
  align-items: baseline;

  .help-content {
    color: $white;
    font-size: 30px;
    line-height: 36px;
    background-color: map-get($mat-streappblauw, 500);
    margin-top: 15%; /* 15% from the top and centered */
    padding: 20px;
    width: 20%;

    @media only screen and (max-width: 767px) {
      width: 40%;
    }

    @media only screen and (max-width: 767px) {
      width: 60%;
    }

    @media only screen and (max-width: 480px) {
      width: 90%;
    }

    .app-buttons {
      margin-top: 20px;

      a {
        color: $white;
      }
    }
  }
}

.appBox {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  .appBox-content {
    color: $white;
    font-size: 24px;
    line-height: 30px;
    background-color: map-get($mat-streappblauw, 500);
    margin-top: auto;
    margin-bottom: auto;
    padding: 20px;
    width: 100%;

    a {
      color: white;
      text-decoration: none;
    }
  }
}

@keyframes zoominout {
  0% {
    -webkit-transform: scale(1.5, 1.5);
    -moz-transform: scale(1.5, 1.5);
    -ms-transform: scale(1.5, 1.5);
    -o-transform: scale(1.5, 1.5);
    transform: scale(1.5, 1.5);
  }

  10% {
    -webkit-transform: scale(1.5, 1.5);
    -moz-transform: scale(1.5, 1.5);
    -ms-transform: scale(1.5, 1.5);
    -o-transform: scale(1.5, 1.5);
    transform: scale(1.5, 1.5);
  }

  40% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  60% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  90% {
    -webkit-transform: scale(1.5, 1.5);
    -moz-transform: scale(1.5, 1.5);
    -ms-transform: scale(1.5, 1.5);
    -o-transform: scale(1.5, 1.5);
    transform: scale(1.5, 1.5);
  }

  100% {
    -webkit-transform: scale(1.5, 1.5);
    -moz-transform: scale(1.5, 1.5);
    -ms-transform: scale(1.5, 1.5);
    -o-transform: scale(1.5, 1.5);
    transform: scale(1.5, 1.5);
  }
}

/* Marker styling */

.marker-container {
  z-index: 1;

  &:hover {
    z-index: 2;
  }
}

.marker {
  .pin {
    width: 30px;
    height: 30px;
    border-radius: 50% 50% 50% 50%;
    background: map-get($mat-streappblauw, 500);
    position: absolute;
    z-index: 1;
    top: -25px;
    left: 5px;
    margin: -20px 0 0 -20px;

    &.ad {
      background-color: map-get($green, 300);
    }

    &.ad:after {
      border-top: 20px solid map-get($green, 300);
    }

    &.reporter {
      background-color: map-get($mat-streappaccent, 500);
    }

    &.reporter:after {
      border-top: 20px solid map-get($mat-streappaccent, 500);
    }

    .marker-title {
      .filler {
        width: 50px;
        height: 50px;
        top: -10px;
        position: absolute;
        float: left;
      }

      .title {
        z-index: 1;
        color: $white;
        white-space: nowrap;
        font-size: 14px;
        position: absolute;
        top: -10px;
        padding: 5px;
        background-color: $grey;
        border-radius: 5px;
        margin-left: 50px;
        display: none;
        max-width: 250px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .date {
        font-size: 12px;
      }
    }

    &:hover {
      z-index: 99;

      .marker-title {
        .title {
          display: inline;
        }
      }
    }
  }

  .pin:before {
    content: "";
    width: 14px;
    height: 14px;
    margin: 8px 0 0 8px;
    background: #e6e6e6;
    position: absolute;
    border-radius: 50%;
    z-index: 1;
  }

  .pin:after {
    content: "";
    width: 0px;
    height: 0px;
    top: 24px;
    left: 3px;
    border: 12px solid transparent;
    border-top: 20px solid map-get($mat-streappblauw, 500);
    position: absolute;
  }

  .bounce {
    animation-name: bounce;
    animation-fill-mode: both;
    animation-duration: 1s;
  }

  .pulse {
    background: #d6d4d4;
    border-radius: 50%;
    height: 14px;
    width: 14px;
    position: absolute;
    top: -15px;
    left: 5px;
    margin: 11px 0px 0px -12px;
    transform: rotateX(55deg);
    z-index: -2;
  }

  .pulse:after {
    content: "";
    border-radius: 50%;
    height: 40px;
    width: 40px;
    position: absolute;
    margin: -13px 0 0 -13px;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 0;
    box-shadow: 0 0 1px 2px map-get($mat-streappblauw, 500);
    animation-delay: 1.1s;

    .ad {
      box-shadow: 0 0 1px 2px map-get($green, 300);
    }
  }

  @keyframes pulsate {
    0% {
      transform: scale(0.1, 0.1);
      opacity: 0;
    }

    50% {
      opacity: 1;
    }

    100% {
      transform: scale(1.2, 1.2);
      opacity: 0;
    }
  }

  @keyframes bounce {
    0% {
      opacity: 0;
      transform: translateY(-2000px);
    }

    60% {
      opacity: 1;
      transform: translateY(30px);
    }

    80% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }
}

@media only screen and (max-width: 767px) {
  .desktop-width-limiter {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }
}
