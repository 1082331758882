$brand-secondary: #ffffff;

$white: #ffffff;
$black: #000000;
$transparent: rgba(0,0,0,0);
$secondary-support: #d4dde7;

$zwart-5: #F9F9F9;
$zwart-10: #f2f2f2;
$zwart-50: #E4E4E4;
$zwart-75: #808080;
$zwart-100: #BCBCBC;
$zwart-200: #909090;
$zwart-300: #636363;
$zwart-400: #414141;
$zwart-500: #202020;
$zwart-600: #1C1C1C;
$zwart-700: #161616;
$zwart-800: #111111;
$zwart-900: #080808;
$zwart-1000: #000000;

$grey: #757171;

$mat-streappblauw: (
  10: #EBF0F5,
  50: #DFE8F0,
  100: #B3C8DA,
  200: #80A3C1,
  300: #4D7EA8,
  400: #266295,
  500: #004682,
  600: #003D71,
  700: #00315C,
  800: #002647,
  900: #00182E,
  1000: #1E5DAD,
  contrast: (
    10: $zwart-500,
    50: $zwart-500,
    100: $zwart-500,
    200: $zwart-500,
    300: $white,
    400: $white,
    500: $white,
    600: $white,
    700: $white,
    800: $white,
    900: $white,
  )
);

$mat-streappgoud: (
  500: #be965a,
  1000: #f8a61c,
  contrast: (
    500: $zwart-500,
  )
);

$lichtblauw: (
  100: #B3D5F3,
  300: #4D9CE2,
  500: #0072D6,
  700: #005FB3,
  contrast: (
    100: $zwart-500,
    300: $zwart-500,
    500: $white,
    700: $white,
  )
);

$rood: (
  100: #F4B3B3,
  300: #E44D4D,
  500: #D90000,
  700: #BA0000,
  900: #8A0000,
  contrast: (
    100: $zwart-500,
    300: $zwart-500,
    500: $white,
    700: $white,
    900: $white,
  )
);

$green: (
  100: #b3d9b3,
  300: #00b050,
  500: #008000,
  700: #005c00,
  900: #003b00,
  contrast: (
    100: $zwart-500,
    300: $zwart-500,
    500: $white,
    700: $white,
    900: $white,
  )
);

$oranje: (
  500: #ed6d22,
  contrast: (
    500: $white,
  )
);

$mat-streappaccent: (
  100: #ffe6b3,
  200: #ffd88a,
  300: #ffc44d,
  400: #fcb831,
  500: #ffaa00,
  700: #d99100,
  900: #a87000,
  contrast: (
    100: $zwart-500,
    200: $zwart-500,
    300: $zwart-500,
    400: $zwart-500,
    500: $zwart-500,
    700: $white,
    900: $white,
  )
);

$danger: map_get($rood, 500);
$warning: map_get($oranje, 500);
$success: map_get($green, 500);

$boa: #0089ff;
