@use "@angular/material" as mat;
@import "@angular/material/theming";
@import "palette-streapp";

@font-face {
  font-family: "Arial, sans-serif";
}

$general-typography: mat.define-legacy-typography-config(
  $font-family: '"Arial", sans-serif',
);
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($general-typography);`
@include mat.all-legacy-component-typographies($general-typography);
@include mat.legacy-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://www.google.com/design/spec/style/color.html
$streapp-primary: mat.define-palette($mat-streappblauw);
$streapp-accent: mat.define-palette($mat-streappaccent);

// The warn palette is optional (defaults to red).
$streapp-warn: mat.define-palette($rood);

// Create the theme object (a Sass map containing all of the palettes).
$streapp-theme: mat.define-light-theme(
  (
    color: (
      primary: $streapp-primary,
      accent: $streapp-accent,
      warn: $streapp-warn,
    ),
    typography: $general-typography,
    density: 0,
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-legacy-component-themes($streapp-theme);
